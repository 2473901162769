<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Assinaturas</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          cols="12"
          lg="6"
          xl="6"
          class="col-bordered"
          style="text-align: center"
        >
          <span class="key-title">
            Assinatura
            {{
              relatorio.assinaturaProdutorOuResponsavel === '2'
                ? 'do responsável'
                : 'do(a) produtor(a)'
            }}
          </span>
          <br />
          <img
            :src="
              relatorio.assinaturaProdutorOuResponsavel === '1'
                ? relatorio.assinaturaProdutor
                : relatorio.assinaturaResponsavel
            "
            alt="Não foi assinado!!!"
            width="auto"
            height="300px"
            class="assinatura"
          />
          <br />
          <span class="key-title">
            {{
              relatorio.assinaturaProdutorOuResponsavel === '2'
                ? relatorio.nomeResponsavel
                : relatorio.fazenda.produtor.Nome
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.assinaturaConsultor"
          cols="12"
          lg="6"
          xl="6"
          class="col-bordered"
          style="text-align: center"
        >
          <span class="key-title">Assinatura consultor(a)</span> <br />
          <img
            v-if="relatorio.assinaturaConsultor"
            :src="relatorio.assinaturaConsultor"
            alt="Não foi assinado!!!"
            width="auto"
            height="300px"
            class="assinatura"
          />
          <br /><span class="key-title">
            {{ relatorio.consultor.pessoafisica.Nome }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProximaVisita',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}

.assinatura {
  transform: rotate(90deg);
  margin-top: -40px;
  margin-bottom: -40px;
  border: solid 2px #333;
  border-radius: 10px;
}
</style>
