import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import { ENV_APP } from '../../../env'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Impresso em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss')
    return str
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)
  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório lactalis')

  doc.text(
    'Relatório lactalis',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    horaInicio,
    horaTermino,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  const dataAtendimento = _ref.dataAtendimento

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + smallGutterY + boxHeight * 2,
  )

  doc.text(consultor, startX + smallGutterX, startY + gutterY + boxHeight * 2)

  doc.text(
    'Matrícula:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + smallGutterY + boxHeight,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + gutterY + boxHeight,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )
  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  // doc.rect(
  //   startX + tableLimit / 3,
  //   startY + boxHeight * 2,
  //   line,
  //   boxHeight,
  //   'F',
  // )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Data:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    dataAtendimento || '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Horário do início:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaInicio || '-',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaTermino || ' - ',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )
  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function datas(doc, _ref, _ref2) {
  const { dataDaOPU, dataDaTransferencia, previsaoParto } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Datas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Data da aspiração:',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    dataDaOPU || '-',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Data da transferência:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    dataDaTransferencia || ' - ',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Data da previsão do parto:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    previsaoParto,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )
  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.rect(startX, cursor, tableLimit, line, 'F')
    doc.addPage()
    cursor = margin + 5
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function tables(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    startX,
    tableLimit,
    smallGutterX,
    smallGutterY,
    mediumFontSize,
    heightLimit,
    margin,
    fontSize,
  } = config

  if (!relatorio.listaEmbrioes) return cursor

  let embrioes = JSON.parse(relatorio.listaEmbrioes)
  embrioes = embrioes.map(item => Object.values(item))

  if (cursor + boxHeight * 6 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  cursor += smallBoxHeight

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Embriões', startX, cursor)
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  if (embrioes && embrioes.length != 0) {
    doc.rect(startX, cursor, line, boxHeight / 2 + 4, 'F') // esquerda
    doc.rect(startX + tableLimit, cursor, line, boxHeight / 2 + 4, 'F') // direita
    doc.rect(startX, cursor, tableLimit, line, 'F')

    doc.text(
      'Identificação dos embriões:',
      startX + tableLimit / 1000 + smallGutterX,
      cursor + smallGutterY,
    )

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, boxHeight, config)

    autoTable(doc, {
      head: [
        [
          'Doadora',
          'Receptora',
          'Nome do touro',
          'Qualidade do embrião',
          'Raça',
          'Grau sanguíneo',
        ],
      ],
      body: [...embrioes],
      headStyles: {
        fillColor: [255, 255, 255],
        fontStyle: 'bold',
        textColor: [0, 0, 0],
      },
      bodyStyles: {
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      theme: 'grid',
      startY: cursor,
    })

    doc.rect(startX, cursor, tableLimit, line, 'F') // superior
  }

  cursor = checkAddPage(doc, cursor, boxHeight * 2, config)

  return doc.lastAutoTable.finalY
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    tableLimit,
    heightLimit,
    margin,
    startX,
    smallGutterY,
    line,
  } = config

  cursor += config.boxHeight
  if (cursor + boxHeight * 7 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.assinaturaProdutorOuResponsavel) {
    if (relatorio.assinaturaProdutorOuResponsavel === '1') {
      produtor.src = relatorio.assinaturaProdutor
      doc.addImage(
        produtor,
        'PNG',
        startX + 50,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    } else {
      produtor.src = relatorio.assinaturaResponsavel
      doc.addImage(
        produtor,
        'PNG',
        startX + 50,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    const consultor = new Image()
    consultor.src = relatorio.assinaturaConsultor

    if (relatorio.assinaturaConsultor) {
      doc.addImage(
        consultor,
        'PNG',
        tableLimit - 180,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

    if (relatorio.assinaturaProdutorOuResponsavel === '1') {
      doc.text(
        relatorio.fazenda.produtor.Nome,
        tableLimit / 3.5,
        cursor + 100 + smallGutterY,
        null,
        null,
        'center',
      )
      doc.text(
        'Assinatura produtor(a)',
        tableLimit / 3.5,
        cursor + 115 + smallGutterY,
        null,
        null,
        'center',
      )
    } else {
      doc.text(
        relatorio.nomeResponsavel || 'Não informado',
        tableLimit / 3.5,
        cursor + 100 + smallGutterY,
        null,
        null,
        'center',
      )
      doc.text(
        'Assinatura do responsável',
        tableLimit / 3.5,
        cursor + 115 + smallGutterY,
        null,
        null,
        'center',
      )
    }

    doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
    doc.text(
      relatorio.consultor.pessoafisica.Nome,
      startX + 420,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura consultor(a)',
      startX + 420,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    produtor.src = relatorio.assinaturas.assinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )

    const consultor = new Image()
    consultor.src = relatorio.assinaturas.assinaturaConsultor
    if (relatorio.assinaturas.assinaturaConsultor) {
      doc.addImage(
        consultor,
        'PNG',
        tableLimit - 180,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    doc.rect(startX + 25, cursor + 100, tableLimit / 3, line, 'F')
    doc.text(
      relatorio.assinaturas.NomeResponsavel
        ? relatorio.assinaturas.NomeResponsavel
        : relatorio.fazenda.produtor.Nome,
      startX + 65,
      cursor + 100 + smallGutterY,
    )
    doc.text(
      'Assinatura ' +
        (relatorio.assinaturas.NomeResponsavel
          ? 'do responsável'
          : 'do(a) produtor(a)'),
      startX + 65,
      cursor + 115 + smallGutterY,
    )

    doc.rect(startX + 320, cursor + 100, tableLimit / 3, line, 'F')
    doc.text(
      relatorio.consultor.pessoafisica.Nome,
      startX + 360,
      cursor + 100 + smallGutterY,
    )
    doc.text(
      'Assinatura consultor(a)',
      startX + 360,
      cursor + 115 + smallGutterY,
    )
  }

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 10, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new jsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })

    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      let cursor = 0
      header(doc, config, data)
      subHeader(doc, data, config)
      config.startY = config.startY + config.boxHeight * 4 + 35
      datas(doc, data, config)
      config.startY = config.startY + config.boxHeight * 4 + 50
      cursor = 300
      cursor = tables(doc, data, config, cursor)
      config.startY = cursor + config.boxHeight

      cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
      const pageCount = doc.internal.getNumberOfPages()

      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        rodapeImpressao(
          doc,
          config,
          cursor + config.boxHeight,
          String(i) + ' de ' + String(pageCount),
        )
      }
      doc
        .save(
          `relatorio-embrioes-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
          { returnPromise: true },
        )
        .then(() => {
          return resolve(true)
        })
    } catch (err) {
      reject(err)
    }
  })
}
