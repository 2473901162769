<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Embriões</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <span class="key-title">Identificação dos embriões</span>
        <br />
        <br />
        <v-card
          v-if="relatorio.listaEmbrioes"
          width="100vw"
          style="border: none"
        >
          <template>
            <v-simple-table fixed-header height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Doadora</th>
                    <th class="text-left">Receptora</th>
                    <th class="text-left">Nome do touro</th>
                    <th class="text-left">Qualidade do embrião</th>
                    <th class="text-left">Raça</th>
                    <th class="text-left">Grau sanguíneo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(animal, index) in JSON.parse(
                      relatorio.listaEmbrioes,
                    )"
                    :key="index"
                  >
                    <td>{{ animal.nomeOuNumeroDaDoadora }}</td>
                    <td>{{ animal.nomeOuNumeroDaReceptora }}</td>
                    <td>{{ animal.nomeDoTouro }}</td>
                    <td>{{ animal.qualiudadeEmbriao }}</td>
                    <td>{{ animal.recaEmbriao }}</td>
                    <td>{{ animal.grauSanguineo }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Embrioes',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      chartOptions: {
        legend: {
          position: 'bottom',
          align: 'center',
        },
        chartArea: { height: '85%', width: '100%' },
        tooltip: { isHtml: true },
      },
    }
  },
  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
